import Vue from 'vue';
import i18n from './i18n';
import App from './components/App/App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import http from "./plugins/http";
const FloatingLabel = () => import('vue-simple-floating-labels');
const DatePicker = () => import('v-calendar/lib/components/date-picker.umd');
import { setupCalendar} from 'v-calendar';
import moment from "moment-timezone";
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueRecaptcha from 'vue-recaptcha';
import VueImg from 'v-img';
import VueLazyload from "vue-lazyload";
import VueCookies from 'vue-cookies';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import '../scss/custom.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css'

moment.tz.setDefault('Europe/Tallinn');
Vue.prototype.$moment = moment;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(http);
Vue.use(VuejsDialog);
Vue.use(VueToast, {
  duration: 4000,
  dismissible: true,
});
Vue.use(VueImg);
Vue.use(VueLazyload);
Vue.use(VueCookies);

Vue.component('floating-label', FloatingLabel);
Vue.component('date-picker', DatePicker);
Vue.component('vue-recaptcha', VueRecaptcha);

setupCalendar({
  locales: {
    'et': {
      firstDayOfWeek: 2,
      masks: {
        L: "YYYY-MM-DD"
      }
    },
    'en-US': {
      formats: {
        navMonths: 'MM',
      },
      firstDayOfWeek: 2,
      masks: {
        L: "YYYY-MM-DD"
      }
    }
  }
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  i18n,
  data() {
    return {
      appConfig: undefined,
      configLoaded: false,
    }
  },
  // Load app config into the Vue $root element.
  // It is accessible throughout the application using "this.$root.appConfig" syntax
  beforeCreate() {
    this.$http().get('/config')
        .then(data => this.appConfig = data.data)
        .finally(() => this.configLoaded = true)
    ;
  }
}).$mount('#app');
