import axios from 'axios';

const http = {
    install(Vue) {
        Vue.prototype.$http = function () {
            return axios.create({
                baseURL: '/api',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            });
        }
    }
};

export default http;
